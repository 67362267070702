<script setup lang="ts">
import type {Toast as ToastConfig} from "~/stores/toasts";

const { t } = useI18n();

const props = defineProps({
  toast: {
    type: Object as () => ToastConfig,
    required: true
  }
});

const el = ref();

const closeBtnClass = computed<String>(() => {
  if (props.toast.class?.includes('text-bg-warning') || props.toast.class?.includes('text-bg-light')) {
    return '';
  }

  return 'btn-close-white';
});

onMounted(() => {
  useBootstrap().Toast.getOrCreateInstance(el.value).show();
});
</script>
<template>
  <div
    ref="el"
    class="toast fade"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
    :class="toast.class"
    :data-bs-delay="toast.delay || 3000"
  >
    <div
      v-if="toast.title"
      class="toast-header"
    >
      <span class="me-auto fw-bold">{{ toast.title }}</span>
    </div>
    <div class="d-flex">
      <div class="toast-body">
        {{ toast.content }}
      </div>
      <button
        type="button"
        class="btn-close me-2 m-auto"
        data-bs-dismiss="toast"
        :aria-label="t('close')"
        :class="closeBtnClass"
      />
    </div>
  </div>
</template>
