export interface Toast {
    title?: string,
    content: string,
    class?: string,
    delay?: number
}

export const useToastsStore = defineStore('toasts', () => {
    const toasts = ref<Toast[]>([]);

    const addToast = (toast: Toast) => {
        toasts.value.push(toast)
    };

    const addInfo = (text: string, title?: string) => {
        addToast({
            title,
            content: text,
            class: "text-bg-info text-white"
        })
    };

    const addSuccess = (text: string, title?: string) => {
        addToast({
            title,
            content: text,
            class: "text-bg-success"
        })
    };

    const addWarning = (text: string, title?: string) => {
        addToast({
            title,
            content: text,
            class: "text-bg-warning"
        })
    };

    const addError = (text: string, title?: string) => {
        addToast({
            title,
            content: text,
            class: "text-bg-danger"
        })
    };

    const addPrimary = (text: string, title?: string) => {
        addToast({
            title,
            content: text,
            class: "text-bg-primary"
        })
    };

    const addSecondary = (text: string, title?: string) => {
        addToast({
            title,
            content: text,
            class: "text-bg-secondary"
        })
    };

    const addLight = (text: string, title?: string) => {
        addToast({
            title,
            content: text,
            class: "text-bg-light"
        })
    };

    return {
        toasts,
        addInfo,
        addSuccess,
        addWarning,
        addError,
        addPrimary,
        addSecondary,
        addLight
    };
});
