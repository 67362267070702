<script setup lang="ts">
import QueueItem from "~/dto/queue/QueueItem";

const { t } = useI18n();

const props = defineProps({
  item: {
    type: Object as () => QueueItem,
    required: true
  },
  iconSize: {
    type: String,
    default: 'icon-lg',
  }
});

const waitingDurationOutput = computed<{h: number, m: number} | null>(() => {
  const duration = waitingDuration(props.item);

  if (!duration) {
    return null;
  }

  const hours = Math.floor(duration.hours);
  const minutes = Math.floor(duration.minutes);

  switch (true) {
    case hours > 0 && minutes > 0:
      return t('navbar.tracking.queue_time.time_hm', {h: hours, m: minutes});
    case hours > 0 && minutes === 0:
      return t('navbar.tracking.queue_time.time_h', {h: hours});
    case hours == 0 && minutes > 0:
      return t('navbar.tracking.queue_time.time_m', {m: minutes});
    default:
      return null;
  }
});
</script>

<template>
  <div
    v-if="waitingDurationOutput"
    v-tooltip="t('navbar.tracking.queue_time.title')"
  >
    <i
      class="cil-clock icon me-1"
      :class="iconSize"
    /> {{ waitingDurationOutput }}
  </div>
</template>
