<script setup lang="ts">
const store = useToastsStore();
const { toasts } = storeToRefs(store);
</script>

<template>
  <div class="toast-container position-fixed top-0 end-0 p-3">
    <BootstrapToast
      v-for="toast in toasts"
      :key="toast.content"
      :toast="toast"
    />
  </div>
</template>